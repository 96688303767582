import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import React, { Suspense } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

const Layout = React.lazy(() => import('./components/common/Layout'));
const Dashboard = React.lazy(() => import('./pages/Dashboard/Dashboard.page'));

function App() {
	return (
		<Router>
			<Switch>
				<Route path="/dashboard">
					<Suspense fallback={null}>
						<Dashboard />
					</Suspense>
				</Route>
				<Route path="/">
					<Suspense fallback={null}>
						<Layout />
					</Suspense>
				</Route>
			</Switch>
		</Router>
	);
}

export default App;
